import React from 'react'

const Teacher = () => {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.85003 14.625C8.78281 14.625 8.71559 14.6346 8.65147 14.6548C8.28697 14.7699 7.90363 14.8438 7.50003 14.8438C7.09644 14.8438 6.7131 14.7699 6.34832 14.6548C6.28419 14.6346 6.21726 14.625 6.15004 14.625C4.4046 14.625 2.99076 16.0053 3.00005 17.7045C3.00398 18.4225 3.6112 19 4.35004 19H10.65C11.3889 19 11.9961 18.4225 12 17.7045C12.0093 16.0053 10.5955 14.625 8.85003 14.625ZM7.50003 13.75C8.99122 13.75 10.2 12.5748 10.2 11.125C10.2 9.67523 8.99122 8.5 7.50003 8.5C6.00885 8.5 4.80004 9.67523 4.80004 11.125C4.80004 12.5748 6.00885 13.75 7.50003 13.75ZM19.65 5H8.85003C8.10556 5 7.50003 5.6084 7.50003 6.35598V7.625C8.15872 7.625 8.76847 7.81039 9.30003 8.11172V6.75H19.2V14.625H17.4V12.875H13.8V14.625H11.6558C12.193 15.0814 12.5873 15.684 12.7721 16.375H19.65C20.3945 16.375 21 15.7666 21 15.019V6.35598C21 5.6084 20.3945 5 19.65 5Z" fill="#0065FF" />
            </svg>
        </div>
    )
}

export default Teacher