import styled from "@emotion/styled";
import { IInputContentStyled } from "./Input.types";

export const InputContentStyled = styled("div")<IInputContentStyled>`
  input {
    background: #f5f5f5;
    border-radius: 16px;
    border: 1px solid ${(props) => (props.isError ? "red" : "#EDF1FF")};
    width: 100%;
    color: #232323;
    padding: 14px 12px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    
    &:-webkit-autofill {
      background-color: #f6f6f6 !important;
    }
    
    &::placeholder {
      color: #bdbdbd;
    }
  }
  .input{
    position: relative;
    .password{
      position: absolute;
      right: 12px;
      top:50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

`;
