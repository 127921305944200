import React from "react";

const UploadIcon = (props: any) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_61_906)">
        <path
          d="M32.25 16.7333C31.6902 13.8962 30.1627 11.3414 27.9284 9.50537C25.6942 7.66935 22.8918 6.66597 20 6.66663C15.1833 6.66663 11 9.39996 8.91667 13.4C6.46706 13.6647 4.20168 14.8253 2.55581 16.6588C0.909952 18.4923 -0.00028617 20.8694 6.74879e-08 23.3333C6.74879e-08 28.85 4.48333 33.3333 10 33.3333H31.6667C36.2667 33.3333 40 29.6 40 25C40 20.6 36.5833 17.0333 32.25 16.7333ZM23.3333 21.6666V28.3333H16.6667V21.6666H11.6667L20 13.3333L28.3333 21.6666H23.3333Z"
          fill="#BDBDBD"
        />
      </g>
      <defs>
        <clipPath id="clip0_61_906">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadIcon;
