import { useLocation, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";

import { SIDEBAR_LIST } from "./Sidebar.constants";
import { SidebarHeaderStyled, SidebarWrapper } from "./Sidebar.style";
import Exit from "./assets/Exit";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = () => {
    navigate("/login");
    localStorage.removeItem("token")
  };

  return (
    <SidebarWrapper>
      <div>
        <SidebarHeaderStyled>
          <div className="menu-icon">
            {" "}
            <span>Ziyo</span>kor
          </div>
        </SidebarHeaderStyled>
        <ul>
          {SIDEBAR_LIST.map((item) => {
            return (
              <li
                key={item.key}
                onClick={() => navigate(item.path)}
                className={`text-black ${
                  (location.pathname === item.path ||
                    location.pathname === item.path.split("?")[0]) &&
                  "current-path"
                }`}
              >
                <div>{item.icon}</div>
                <div className="text">{item.text}</div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="logout" onClick={logout}>
        <p>Chiqish</p>
        <Exit />
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
