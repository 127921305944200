import React from 'react'

const Branch = () => {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 12C12.4125 12 12.7657 11.8432 13.0598 11.5296C13.3533 11.2165 13.5 10.84 13.5 10.4C13.5 9.96 13.3533 9.5832 13.0598 9.2696C12.7657 8.95653 12.4125 8.8 12 8.8C11.5875 8.8 11.2345 8.95653 10.941 9.2696C10.647 9.5832 10.5 9.96 10.5 10.4C10.5 10.84 10.647 11.2165 10.941 11.5296C11.2345 11.8432 11.5875 12 12 12ZM12 20C9.9875 18.1733 8.4845 16.4765 7.491 14.9096C6.497 13.3432 6 11.8933 6 10.56C6 8.56 6.60325 6.96667 7.80975 5.78C9.01575 4.59333 10.4125 4 12 4C13.5875 4 14.9843 4.59333 16.1903 5.78C17.3967 6.96667 18 8.56 18 10.56C18 11.8933 17.5033 13.3432 16.5098 14.9096C15.5157 16.4765 14.0125 18.1733 12 20Z" fill="#0065FF" />
            </svg>
        </div>
    )
}

export default Branch