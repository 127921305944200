import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import get from "lodash.get";
import toast from "react-hot-toast";
import { Controller } from "react-hook-form";
import { REQUEST_STATUS } from "../../../hooks/useRequest/useRequest.constants";
import { useRequest } from "../../../hooks/useRequest/useRequest";
import Label from "../Label/Label";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { setErrorFunction } from "../UploadFile/utils/setErrorFunction";
import { FocusInputStyled } from "../UploadFile/UploadFile.style";
import { firstErrorField } from "../../../utils/firstErrorField/firstErrorField";
import SliceUrl from "components/common/SliceUrl/SliceUrl";

import UploadIcon from "./assets/UploadImageIcon";
import UploadImageIcon from "./assets/UploadIcon";
import ChangeImageIcon from "./assets/ChangeImageIcon";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { IUploadImageProps } from "./UploadImage.types";
import {
  UploadImageStyled,
  UploadedImage,
  UploadContent,
} from "./UploadImage.style";

const UploadImage: FC<IUploadImageProps> = ({
  upload = () => {},
  errors,
  placeholder,
  control,
  FORM_NAMES,
  name = "file",
  dataUrl = "",
  accept = ".svg, .png, .jpg,",
  clearErrors,
  setError,
  rules = { required: false },
  label = "",
  id,
  setImage,
  setBranchImages,
  img,
  setDisabled,
}) => {
  const [uploadClient, uploadData, uploadStatus, uploadError] = useRequest();
  const [fileName, setFileName] = useState<string>("");
  const { required } = rules;
  const { value } = required;

  const uploadRef = useRef<HTMLInputElement>(null);

  const focus = async () => {
    if (uploadRef.current !== null) {
      if (errors) {
        if (!!get(errors, `${name}`, undefined)) {
          let key = firstErrorField(FORM_NAMES, errors);

          if (key === name) {
            uploadRef?.current?.focus();
          }
        }
      }
    }
  };

  useEffect(() => {
    focus();
  }, [errors && get(errors, `${name}`), uploadRef]);

  const fileUpload = async (e: any) => {
    setDisabled(true);
    if (e.target.files[0]) {
      try {
        const formState = new FormData();
        setFileName(e.target.files[0].name);
        formState.append("file", e.target.files[0]);
        formState.append("type", "img");
        await uploadClient.post("upload", formState);
      } catch (e: any) {
        setImage(undefined);
        setErrorFunction(name, setError, errors);
      }
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (uploadStatus === REQUEST_STATUS.success) {
      setDisabled(false);
      upload(uploadData?.data);
      setImage(uploadData?.data);
      if (setBranchImages) {
        const newImg = {
          isMain: false,
          url: uploadData?.data,
        };
        setBranchImages((p: any) => [...p, newImg]);
      }
      toast.success(uploadData?.message);
    } else if (uploadStatus === REQUEST_STATUS.failed) {
      setImage(undefined);
      setErrorFunction(name, setError, errors);
    }
  }, [uploadStatus]);

  const renderUploadContext = () => {
    if (uploadStatus === REQUEST_STATUS.loading) {
      return (
        <div className=" loaderContent  justify-content-center align-items-center">
          <CircularProgress />
        </div>
      );
    } else if (uploadStatus === REQUEST_STATUS.success && !img)
      return (
        <div className="uploadIcon">
          <UploadIcon />
          <p>{placeholder}</p>
        </div>
      );
    else if (uploadStatus === REQUEST_STATUS.success || img) {
      return (
        <UploadedImage>
          <div className="img">
            <img
              className="uploadImage"
              src={"https://ziyokoredu.uz/admin-api/" + img}
              alt={"UploadImage"}
            />
          </div>
          <div className="action">
            <div className="uploaded">
              <UploadImageIcon />
              <SliceUrl text={fileName} />
            </div>
            <div className="change">
              {setBranchImages ? <AddCircleOutlineIcon /> : <ChangeImageIcon />}
            </div>
          </div>
        </UploadedImage>
      );
    } else
      return (
        <div className="uploadIcon">
          <UploadIcon />
          <p>{placeholder}</p>
        </div>
      );
  };

  const renderErrorMessage = useMemo(() => {
    if (!!errors) {
      if (!!get(errors, `${name}`, undefined)) {
        return <ErrorMessage value={get(errors, `${name}`)?.message} />;
      }
      return null;
    }
    return null;
  }, [errors && get(errors, `${name}`)]);

  return (
    <UploadImageStyled>
      {label && <Label required={value} value={label} />}
      <FocusInputStyled type="text" ref={uploadRef} />
      <UploadContent
        img={img}
        error={!!errors && !!get(errors, `${name}`, undefined)}
      >
        <label
          htmlFor={id}
          className={`${uploadStatus === REQUEST_STATUS.loading && "loading"}`}
        >
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { onChange, ...field } }) => (
              <input
                type="file"
                id={id}
                onChange={(e) => {
                  fileUpload(e);
                }}
                accept={accept}
                disabled={uploadStatus === REQUEST_STATUS.loading}
                {...field}
              />
            )}
          />
        </label>
        {renderUploadContext()}
      </UploadContent>
      {renderErrorMessage}
    </UploadImageStyled>
  );
};

export default UploadImage;
