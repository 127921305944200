import React, { FC } from "react";
import { LabelStyled } from "./Label.style";
import { ILabelProps } from "./Label.types";

const Label: FC<ILabelProps> = ({ value, id, required }) => {
  return (
    <LabelStyled className="mb-2" htmlFor={id}>
      {value}
      <span>{required ? "*" : ""}</span>
    </LabelStyled>
  );
};

export default Label;
