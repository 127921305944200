/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { TableRow, Table, TableBody, IconButton } from "@mui/material";
import { get } from "lodash";
import browserStorage from "services/storage/browserStorage";
import { PaginationTableContext } from "../context";
import { DEFAULT_ROW_LIMIT } from "../constants/PaginationTable.constants";
import TableSkeleton from "../../Skeletons/TableSkeleton/TableSkeleton";
import PaginationSkeleton from "../../Skeletons/TableSkeleton/PaginationSkeleton";
import { Modal, SaveButton } from "components";
import {
  StyledIndexTableCell,
  StyledPaper,
  StyledTableBodyRow,
  StyledTableContainer,
  StyledTablePagination,
  TableCellBodyStyle,
  TableCellHeaderStyle,
  TableHeadStyled,
} from "../style/PaginationTable.style";
import { TableAction } from "../style/TableHeader";
import { debounce } from "services/debounce/debounce";
import { REQUEST_STATUS } from "hooks/useRequest/useRequest.constants";
import useQueryParams from "hooks/useQueryParams/useQueryParams";
import { IColumn, ITableProps } from "../types/PaginationTable";
// icons
import SearchIcon from "@mui/icons-material/Search";
import Edit from "../assets/Edit";
import Delete from "../assets/Delete";

export default function PaginationTable({
  url,
  columns = [],
  renderItemProps,
  onRowClick,
  getTableData,
  onOpenSidebar,
  render = false,
  deleteAbleItems = true,
  searchable = false,
  onOccupySidebarOpen,
  handleSelectItem,
  isPagination = false,
  ...props
}: ITableProps) {
  const {
    state: { contentStore, deleteItems },
    actions: { getContent, deleteRequest },
  } = useContext(PaginationTableContext);

  const [searchValue, setSearchValue] = useState(
    browserStorage.get("usersSearch") || ""
  );
  const { search, pathname } = useLocation();
  const { getParam, setParam, getAllParams } = useQueryParams();
  const [selectedItems, setSelectedItems] = useState<string>("");
  const [isOpenOpened, setIsOpenOpened] = useState(false);

  const addRemoveItem = (_id: string) => {
    setSelectedItems(_id);
  };

  const searchItems = (str: string) => {
    setSearchValue(str);
    browserStorage.set("usersSearch", str);
    debounceSearchItems(str);
  };

  const debounceSearchItems = useCallback(
    debounce((str: string) => setParam("search", str), 400),
    []
  );

  const rowsPerPage = useMemo(
    () => parseInt(getParam("limit")) || DEFAULT_ROW_LIMIT,
    [search]
  );
  const currentPage = useMemo(() => parseInt(getParam("page")) || 1, [search]);
  const data = useMemo(
    () =>
      get(contentStore, "res.data.data") || get(contentStore, "res.data", []),
    [contentStore]
  );
  useEffect(() => {
    return () => {
      browserStorage.remove("usersSearch");
    };
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLElement>,
    newPage: number
  ) => {
    setParam("page", newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value);
    setParam("limit", newRowsPerPage);
  };

  const allQuery = () => {
    let str = "";
    Object.keys(getAllParams())?.forEach((key) => {
      if (key === "page") {
        str = str + `${key}=${getAllParams()[key]}&`;
      } else {
        str = str + `${key}=${getAllParams()[key]}&`;
      }
    });
    return "?" + str;
  };

  useEffect(() => {
    renderTableData();
    // if (deleteItems?.status === REQUEST_STATUS.success) {
    // }
  }, [search, deleteItems?.status, render]);
  useEffect(() => {
    handleSelectItem?.(selectedItems);
  }, [selectedItems]);

  const renderTableData = () => {
    getContent(url, getAllParams());
  };
  useEffect(() => {
    getContent(url, getAllParams());
  }, []);

  const [localSearch, setLocalSearch] = useState<string>("");

  const renderHeader = (columns: IColumn[]) => {
    return (
      <>
        <TableHeadStyled>
          <TableRow className="firstRow">
            {columns.map((column: IColumn, index) => (
              <TableCellHeaderStyle key={index}>
                {column.name}
              </TableCellHeaderStyle>
            ))}
            <TableCellHeaderStyle>Harakatlar</TableCellHeaderStyle>
          </TableRow>
        </TableHeadStyled>
      </>
    );
  };

  const renderItem = (row: any, column: IColumn) =>
    column.renderItem
      ? column.renderItem(row, renderItemProps, renderTableData)
      : get(row, `${column.dataKey}`);
  const renderBody = (store: any) => {
    const { status, error } = store;

    if (status === REQUEST_STATUS.failed) return get(error, "message");

    return (
      <TableBody>
        {data?.length === 0 ? (
          <h4 className="px-4 py-4">No items</h4>
        ) : (
          data
            ?.filter((val: any) => {
              if (localSearch === "") return val;
              else if (
                columns
                  ?.map((item: IColumn) => item.dataKey)
                  .some((key: any) =>
                    (get(val, key, "") || "")
                      .toLowerCase()
                      .includes(localSearch.toLowerCase())
                  )
              )
                return val;
            })
            ?.map((row: any, index: number) => (
              <DetailPaneWithTableBody
                index={index}
                onRowClick={onRowClick}
                key={index}
                onOpenSidebar={onOpenSidebar}
                // currentPage={currentPage}
                // rowsPerPage={rowsPerPage}
                columns={columns}
                row={row}
                addRemoveItem={addRemoveItem}
                setIsOpenOpened={setIsOpenOpened}
              >
                {columns?.map((column: IColumn, index: number) => (
                  <TableCellBodyStyle
                    align={column.align || "left"}
                    key={index}
                    component="th"
                    scope="row"
                  >
                    {column.name === "Url" ? (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => window.location.reload()}
                        href={`${
                          process.env.REACT_APP_BASE_URL +
                          renderItem(row, column)
                        }`}
                      >
                        {"..." +
                          renderItem(row, column).substr(
                            renderItem(row, column).length - 10,
                            10
                          )}
                      </a>
                    ) : (
                      <div>{renderItem(row, column)}</div>
                    )}
                  </TableCellBodyStyle>
                ))}
              </DetailPaneWithTableBody>
            ))
        )}
      </TableBody>
    );
  };

  const renderPagination = () => (
    <StyledTablePagination
      rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
      colSpan={columns.length + 1}
      count={get(contentStore, "res.data.total", 0)}
      rowsPerPage={rowsPerPage}
      page={currentPage - 1}
      component="div"
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
  return (
    <>
      <TableAction>
        <div className="first-row">
          <div className="search-row">
            <span>
              <SearchIcon />
            </span>
            {searchable ? (
              <input
                value={searchValue}
                onChange={(e) => searchItems(e.target.value)}
                placeholder={"Qidiruv"}
                autoFocus
              />
            ) : (
              <input
                onChange={(e) => setLocalSearch(e.target.value)}
                placeholder={"Qidiruv"}
              />
            )}
          </div>

          <div className="actions">
            <SaveButton
              className="addBtn"
              onClick={() => onOpenSidebar && onOpenSidebar(true)}
              label="Qo'shish"
            />
          </div>
        </div>
      </TableAction>
      <StyledTableContainer>
        {contentStore?.status === REQUEST_STATUS.loading ||
        deleteItems?.status === REQUEST_STATUS.loading ? (
          <>
            <Table>{renderHeader(columns)}</Table>
            <TableSkeleton tableBodyRowCount={5} columns={columns} />
          </>
        ) : (
          <>
            <Modal
              onSubmit={() => {
                deleteAbleItems && deleteRequest(url, selectedItems);
                setIsOpenOpened(false);
              }}
              onCancel={() => setIsOpenOpened(false)}
              isOpen={isOpenOpened}
              label="Rostdan ham o'chirmoqchimisiz?"
            ></Modal>
            <Table stickyHeader aria-label="custom pagination table">
              {renderHeader(columns)}
              {renderBody(contentStore) ? (
                renderBody(contentStore)
              ) : (
                <h4>Natijalar topilmadi</h4>
              )}
            </Table>
          </>
        )}
        {/* </Table> */}
      </StyledTableContainer>
      {isPagination &&
        (contentStore?.status === REQUEST_STATUS.loading ||
        deleteItems?.status === REQUEST_STATUS.loading ? (
          <PaginationSkeleton />
        ) : (
          renderPagination()
        ))}
    </>
  );
}

const DetailPaneWithTableBody = ({ children, ...props }: any) => {
  const {
    index,
    onRowClick,
    columns,
    row,
    addRemoveItem,
    onOpenSidebar,
    setIsOpenOpened,
  } = props;
  return (
    <>
      <StyledTableBodyRow
        key={index + 1}
        isRowClick={!!onRowClick}
        onClick={() => {
          return onRowClick && onRowClick(row);
        }}
        className="tableBody"
      >
        {children}
        <StyledIndexTableCell style={{ padding: "6px 16px" }}>
          <IconButton onClick={() => onOpenSidebar && onOpenSidebar(true)}>
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => {
              addRemoveItem(row?._id);
              setIsOpenOpened(true);
            }}
          >
            <Delete />
          </IconButton>
        </StyledIndexTableCell>
      </StyledTableBodyRow>
    </>
  );
};
