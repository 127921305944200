import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { IUploadFileStyled } from "./UploadFile.types";

export const UploadFileStyled = styled.div<IUploadFileStyled>`
  position: relative;
  width: 100%;
  height: 150px;
  top:-16px;
  background: #f5f5f5;
  border-radius: 16px;
  border: 1px solid ${(props) => (props.isError ? "red" : "#f1f1f1")};
  overflow: hidden;
  .uploadIcon {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    svg {
      width: 30px;
      height: 30px;
    }
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #bdbdbd;
    }
  }
  .loaderContent {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
      width: 30px !important;
      height: 30px !important;
    }
  }
  label {
    position: absolute;
    top: ${(props) => (props.img ? "60%" : "0")};
    left:0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &:hover {
      cursor: pointer;
    }
    input {
      display: none;
    }
  }
  label.loading {
    opacity: 0.4;
  }
`;

export const UploadFileIconLoader = styled(CircularProgress)`
  width: 25px !important;
  height: 25px !important;
`;

export const FocusInputStyled = styled.input`
  max-width: 0 !important;
  max-height: 0 !important;
  opacity: 0;
  padding: 0 !important;
  border: none !important;
`;

export const UploadedFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
    width: 55%;
    padding: 16px;
    vertical-align: middle;

    .uploadImage {
      width: 100%;
    }
  }
  .action {
    width: 45%;
    height: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .uploaded {
      text-align: center;
    }
  }
`;

