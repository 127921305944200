import { useRequest } from "hooks/useRequest/useRequest";
export const usePaginationTable = (): any => {
  const [getBodyClient, getBodyRes, tableBodyStatus, tableBodyError] =
    useRequest();
  const DEFAULT_PAGE = 1;
  const DEFAULT_LIMIT = 10;
  const getContent = (url: string, body: any) => {
    getBodyClient.post(url + "/pagin", body);
  };
  const [
    deleteItemsClient,
    deleteItemsData,
    deleteItemsStatus,
    deleteItemsError,
  ] = useRequest();
  const deleteRequest = async (dataUrl: string, ids: string) => {
    await deleteItemsClient.deleteRequest(`${dataUrl}/${ids}`);
  };

  return {
    state: {
      contentStore: {
        res: getBodyRes,
        status: tableBodyStatus,
        error: tableBodyError,
      },
      deleteItems: {
        res: deleteItemsData,
        status: deleteItemsStatus,
        error: deleteItemsError,
      },
    },
    actions: { getContent, deleteRequest },
  };
};
