import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  text: string;
}

const SliceUrl: FC<Props> = ({ text }) => {
  return (
    <StyledUrl>
      {text?.length > 20 ? text.slice(0, 20) + "...": text}
    </StyledUrl>
  );
};

export default SliceUrl;

const StyledUrl = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #bdbdbd;
`;
