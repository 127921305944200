import styled from "styled-components";
import { ISidebarStyle } from "./RightSidebar.types";

export const RightSidebarStyled = styled.div<ISidebarStyle>`
  background-color: white;
  left: ${({ open }) => (open ? 0 : "400px")};
  min-width: 400px;
  position: relative;
  width: 400px;
  padding: 42px 24px 24px;
  height: 100vh;
  overflow-y: auto;
  border-radius: 32px 0 0 32px;
  top: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.2s linear;
  z-index: 15;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &::-webkit-scrollbar {
    display: none;
  }
  h2 {
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #232323;
  }
  .btn-group {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const AddStyled = styled.div<ISidebarStyle>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100 vh;
  transition: all 0.2s linear;
  background: ${({ open }) => (open ? "rgba(0, 0, 0, 0.4)" : "transparent")};
  z-index: ${({ open }) => (open ? "14" : "-5")};
  display: flex;
  .shadow {
    width: calc(100% - 400px);
    width: 100%;
    height: 100 vh;
  }
`;
