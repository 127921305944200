import { styled } from "@mui/system";
import {
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

export const TableCellHeaderStyle = styled(TableCell)({
  fontSize: "14px",
});
export const TableHeadStyled = styled(TableHead)`
  margin-bottom: 24px !important;

  .firstRow {
    background: #f5f5f5;
    th{
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #bdbdbd;
      padding: 14px 16px;
      border: none;
      &:first-child {
        border-radius: 16px 0 0 16px;
      }
      &:last-child {
        border-radius: 0 16px 16px 0;
      }
    }
  }
`;

export const TableCellBodyStyle = styled(TableCell)`
  /* tr {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: red;
  } */
`;

export const StyledIndexTableCell = styled(TableCell)({
  // width: "1%",
  "display":"flex",
  "gap":"10px", 
  "alignItems":"center",
});

export const StyledTableContainer = styled(TableContainer)({
  // height: "calc(100% - 40px) !important",
      overflowX: "initial",
  "&::-webkit-scrollbar": {
    width: "10px",
    height: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "12px",
    border: "2px solid rgba(0, 0, 0, 0.18)",
    borderLeft: 0,
    borderRight: 0,
    backgroundColor: "#4340DA",
    zIndex: "100",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#e4e4e4",
    borderRadius: "100px",
  },
  });

export const StyledTableBodyRow = styled(TableRow)<any>(({ isRowClick }) => ({
  "&:hover": {
    cursor: isRowClick && "pointer",
  },
}));

export const StyledPaper = styled(Paper)<any>(() => ({
  minHeight: "300px",
  width: "100%",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "8px",
}));

export const StyledTablePagination = styled(TablePagination)<any>({
  height: "53px",
  marginTop: "16px"
});
// MuiTablePagination-selectLabel

export const StyledDetailPanelTableCell = styled(TableCell)({
  boxShadow: "rgb(223 223 219 / 50%) 0px 0px 20px 0px inset",
});
