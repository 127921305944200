import React from 'react'

const Test = () => {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.20676 10.7864C6.90392 10.1536 6.74654 9.45907 6.7465 8.75536C6.7465 8.37023 6.80129 7.97449 6.91347 7.54715C6.96369 7.35492 7.02674 7.17108 7.09762 6.99277C6.82564 6.84185 6.5267 6.74905 6.20602 6.74905C5.01678 6.74905 4.05409 7.88765 4.05409 9.29384C4.05409 10.2775 4.52718 11.1289 5.21811 11.5525L2.30155 12.9321C2.11979 13.019 2 13.2276 2 13.4607V15.8002C2 16.1137 2.21545 16.3678 2.48048 16.3678H4.20432V13.913C4.20432 13.1977 4.5961 12.5492 5.20419 12.26L7.43525 11.204C7.35181 11.0691 7.27553 10.9297 7.20676 10.7864ZM14.2039 8.20009H21.6158V8.19987L21.6171 8.20009C21.8287 8.20009 22 8.02575 22 7.81098V6.38999C22 6.175 21.8285 6.00088 21.6171 6.00088L21.6158 6.00111V6H13.3334C13.6981 6.48773 13.9592 7.04716 14.1 7.64283C14.1467 7.83926 14.1806 8.02288 14.2039 8.20009ZM22 15.1901C22 14.9751 21.8285 14.801 21.6171 14.801L21.6158 14.8012V14.8001H16.7845V16.8084C16.7845 16.8732 16.7811 16.937 16.7748 17H21.6158V16.9998L21.6171 17C21.8287 17 22 16.8257 22 16.6109V15.1901ZM21.6171 10.4011L21.6158 10.4013V10.4002H13.9445C13.9099 10.4928 13.8734 10.5849 13.8328 10.6762C13.7549 10.851 13.666 11.0205 13.5667 11.1837L15.8034 12.2682C15.9712 12.3523 16.124 12.4646 16.2552 12.6003H21.6158V12.6L21.6171 12.6003C21.8287 12.6003 22 12.4259 22 12.2112V10.7902C22 10.5752 21.8285 10.4011 21.6171 10.4011Z" fill="#0065FF" />
                <path d="M15.6109 13.7153L12.9575 12.4882L11.8354 11.9693C12.3256 11.6699 12.7235 11.208 12.9839 10.6515C13.2011 10.1876 13.3135 9.68413 13.3136 9.17471C13.3136 8.8745 13.2646 8.58966 13.196 8.31439C12.8622 6.98192 11.7888 6 10.5001 6C9.23581 6 8.17869 6.94674 7.82448 8.24156C7.74271 8.53844 7.6862 8.84733 7.6862 9.17449C7.6862 9.74285 7.8231 10.2724 8.0558 10.7335C8.32478 11.268 8.72333 11.7087 9.20847 11.992L8.1339 12.477L5.39441 13.714C5.1562 13.822 5 14.0823 5 14.373V17.2916C5 17.6826 5.28116 18 5.62825 18H15.3717C15.7188 18 16 17.6829 16 17.2916V14.3732C16.0005 14.0834 15.8466 13.8258 15.6109 13.7153Z" fill="#0065FF" />
            </svg>
        </div>
    )
}

export default Test