import styled from "styled-components";
import { sidebarWidth } from "./Sidebar.constants";

export const SidebarWrapper = styled.div`
  width: ${sidebarWidth};
  background-color: #f1f3f5;
  height: 100vh;
  padding: 48px 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    list-style: none;
    margin-top: 32px;
    li {
      display: flex;
      align-items: center;
      padding: 18px 24px;
      gap: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #232323;
      cursor: pointer;
      .text {
        padding-bottom: 6px;
      }
      &.current-path {
        background: #e0e7ee;
      }

      &:hover {
        background: #e0e7ee;
      }
    }
  }

  .logout {
    background: #e0e7ee;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 14px;
    margin: 0 24px;
    cursor: pointer;
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #232323;
      padding-bottom: 4px;
    }
  }
`;
export const SidebarHeaderStyled = styled.div`
  .menu-icon {
    font-style: italic;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    padding-left: 24px;
    span {
      color: #0065ff;
    }
  }
`;
