import { lazy } from "react";
const TestRoutes = lazy(() => import("pages/test"));
const ProjectRoutes = lazy(() => import("pages/project"));
const CourseRoutes = lazy(() => import("pages/course"));
const TeacherRoutes = lazy(() => import("pages/teacher"));
const BranchRoutes = lazy(() => import("pages/branch"));

export const ROUTE_LIST = [
  {
    key: "test_result",
    element: <TestRoutes />,
    path: "/test_result/*",
  },
  {
    key: "project",
    element: <ProjectRoutes />,
    path: "/project/*",
  },
  {
    key: "course",
    element: <CourseRoutes />,
    path: "/course/*",
  },
  {
    key: "teacher",
    element: <TeacherRoutes />,
    path: "/teacher/*",
  },

  {
    key: "branch",
    element: <BranchRoutes />,
    path: "/branch/*",
  }
];
