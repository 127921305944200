import {
  Skeleton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow as MuiTableRow,
} from "@mui/material";
import TableRow from "components/common/PaginationTable/components/TableRow/TableRow";
import { IColumn } from "components/common/PaginationTable/types/PaginationTable";

import { ITableSkeletonProps } from "./TableSkeloton.types";

const TableSkeleton = ({
  columns,
  tableBodyRowCount = 5,
}: ITableSkeletonProps) => {
  let array = new Array(tableBodyRowCount);
  return (
    <>
      {/* <div className="table-header px-3 py-3">
        <div className="action d-flex justify-content-end">
          <div className=" d-flex gap-3">
            <Skeleton variant="rectangular" width={40} height={40} />
            <Skeleton variant="rectangular" width={100} height={40} />
          </div>
        </div>
        <div className="pt-4">
          <Skeleton variant="text" />
        </div>
      </div> */}

      <MuiTable>
        {/* <TableHead>
          <MuiTableRow>
            {columns?.map((item: IColumn, index: number) => (
              <TableCell key={index}>
                <Skeleton
                  sx={{ backgroundColor: "rgba(94, 88, 115,0.4)" }}
                  variant="text"
                />
              </TableCell>
            ))}
          </MuiTableRow>
        </TableHead> */}
        {/* <TableBody> */}
        {[...array].map((_, index) => (
          <TableRow key={index}>
            {columns?.map((item: IColumn, index: number) => (
              <TableCell
                sx={{ width: index === 0 ? 74 : "auto", textAlign: "start" }}
                key={index}
              >
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        ))}
        {/* </TableBody> */}
      </MuiTable>
    </>
  );
};

export default TableSkeleton;
