import { Fragment, Suspense, useEffect, useMemo, useState } from "react";
import { Routes } from "layout/components";
import { Loader, Sidebar } from "../../components";
import { LayoutStyled } from "../style/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import isToken from "utils/isToken/isToken";

const Layout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isFullWidth = useMemo(() => {
    return pathname.includes("/login");
  }, [pathname]);

  useEffect(() => {
    if (!isToken()) {
      navigate("/login");
    }
  }, []);

  return (
    <Fragment>
      {!isFullWidth ? (
        <LayoutStyled>
          <div className="sidebar-content">
            <Sidebar />
          </div>
          <div className={`wrapper`}>
            {/* <Header /> */}
            <div className="wrapper-content">
              <Suspense fallback={<Loader />}>
                <Routes />
              </Suspense>
            </div>
          </div>
        </LayoutStyled>
      ) : (
        <Suspense fallback={<Loader />}>
          <Routes />
        </Suspense>
      )}
    </Fragment>
  );
};

export default Layout;
