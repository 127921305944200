import React from "react";

const UploadIcon = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 4V13C20.5 13.7956 20.8161 14.5587 21.3787 15.1213C21.9413 15.6839 22.7044 16 23.5 16H32.5V33C32.5 33.7956 32.1839 34.5587 31.6213 35.1213C31.0587 35.6839 30.2956 36 29.5 36H19.986C21.4797 34.1911 22.3561 31.9521 22.487 29.6098C22.618 27.2676 21.9967 24.9448 20.7138 22.9807C19.431 21.0166 17.5539 19.514 15.3565 18.6925C13.1592 17.8709 10.7567 17.7733 8.5 18.414V7C8.5 6.20435 8.81607 5.44129 9.37868 4.87868C9.94129 4.31607 10.7044 4 11.5 4H20.5ZM22.5 4.5V13C22.5 13.2652 22.6054 13.5196 22.7929 13.7071C22.9804 13.8946 23.2348 14 23.5 14H32L22.5 4.5ZM20.5 29C20.5 31.3869 19.5518 33.6761 17.864 35.364C16.1761 37.0518 13.8869 38 11.5 38C9.11305 38 6.82387 37.0518 5.13604 35.364C3.44821 33.6761 2.5 31.3869 2.5 29C2.5 26.6131 3.44821 24.3239 5.13604 22.636C6.82387 20.9482 9.11305 20 11.5 20C13.8869 20 16.1761 20.9482 17.864 22.636C19.5518 24.3239 20.5 26.6131 20.5 29ZM16.208 25.292C16.1151 25.1989 16.0048 25.125 15.8833 25.0746C15.7618 25.0242 15.6315 24.9982 15.5 24.9982C15.3685 24.9982 15.2382 25.0242 15.1167 25.0746C14.9952 25.125 14.8849 25.1989 14.792 25.292L9.5 30.586L8.208 29.292C8.02023 29.1042 7.76555 28.9987 7.5 28.9987C7.23445 28.9987 6.97977 29.1042 6.792 29.292C6.60423 29.4798 6.49874 29.7344 6.49874 30C6.49874 30.2656 6.60423 30.5202 6.792 30.708L8.792 32.708C8.88489 32.8011 8.99524 32.875 9.11673 32.9254C9.23822 32.9758 9.36847 33.0018 9.5 33.0018C9.63153 33.0018 9.76178 32.9758 9.88327 32.9254C10.0048 32.875 10.1151 32.8011 10.208 32.708L16.208 26.708C16.3011 26.6151 16.375 26.5048 16.4254 26.3833C16.4758 26.2618 16.5018 26.1315 16.5018 26C16.5018 25.8685 16.4758 25.7382 16.4254 25.6167C16.375 25.4952 16.3011 25.3849 16.208 25.292Z"
        fill="#34A853"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5 13V4H11.5C10.7044 4 9.94129 4.31607 9.37868 4.87868C8.81607 5.44129 8.5 6.20435 8.5 7V18.414C10.7567 17.7733 13.1592 17.8709 15.3565 18.6925C17.5539 19.514 19.431 21.0166 20.7138 22.9807C21.9967 24.9448 22.618 27.2676 22.487 29.6098C22.3561 31.9521 21.4797 34.1911 19.986 36H29.5C30.2956 36 31.0587 35.6839 31.6213 35.1213C32.1839 34.5587 32.5 33.7956 32.5 33V16H23.5C22.7044 16 21.9413 15.6839 21.3787 15.1213C20.8161 14.5587 20.5 13.7956 20.5 13ZM22.5 13V4.5L32 14H23.5C23.2348 14 22.9804 13.8946 22.7929 13.7071C22.6054 13.5196 22.5 13.2652 22.5 13Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default UploadIcon;
