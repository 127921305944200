import { useContext, useEffect } from "react";
import { Input, SaveButton } from "components";
import { useForm } from "react-hook-form";
import { FORM_NAMES } from "../constants/Login";
import { LoginStyled } from "../style/Login";
import { ILoginRequestData } from "../context/LoginContext.types";
import { LoginContext } from "../context";
import { REQUEST_STATUS } from "hooks/useRequest/useRequest.constants";
import browserStorage from "services/storage/browserStorage";
import get from "lodash.get";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Book from "../assets/Book.png";
import Main from "../assets/Main.png";
import Pattern1 from "../assets/Pattern1.png";
import Pattern2 from "../assets/Pattern2.png";
import Rocket from "../assets/Rocket.png";
import Student from "../assets/Student.png";
import Time from "../assets/Time.png";
import Enter from "../assets/Enter";
import Logo from "../assets/Logo.png";

const Login = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginRequestData>();

  const {
    state: {
      loginStates: { loginData, loginStatus },
    },
    actions: { login },
  } = useContext(LoginContext);

  const loginFormSubmit = (loginFormData: ILoginRequestData) => {
    console.log(loginFormData);
    const body: ILoginRequestData = {
      password: loginFormData.password,
      login: loginFormData.login,
    };
    login(body);
  };

  useEffect(() => {
    if (loginStatus === REQUEST_STATUS.success) {
      browserStorage.set("token", get(loginData, "data.token", ""));
      browserStorage.set("AdminTgf", get(loginData, "data.login", ""));
      toast.success(get(loginData, "message"));
      navigate("/test_result?page=1&limit=10");
      window.location.reload();
    }
  }, [loginStatus]);

  return (
    <LoginStyled>
      <div className="login-left">
        <img src={Pattern1} alt="Pattern1" className="pattern1" />
        <img src={Pattern2} alt="Pattern2" className="pattern2" />
        <div className="login-form">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>
          <h2 className="login-page-title mb-4">Xush kelibsiz</h2>
          <form onSubmit={handleSubmit(loginFormSubmit)} id="login">
            <Input
              control={control}
              errors={errors}
              rules={{ required: { value: true, message: "To'ldirishingiz kerak" } }}
              FORM_NAMES={FORM_NAMES}
              name={FORM_NAMES.login}
              label="Login"
              className="mb-3"
              placeholder="Loginni kiriting"
            />
            <Input
              control={control}
              errors={errors}
              rules={{ required: { value: true, message: "To'ldirishingiz kerak" } }}
              FORM_NAMES={FORM_NAMES}
              name={FORM_NAMES.password}
              label="Parol"
              className="mb-4"
              placeholder="Parolni kiriting"
              type="password"
            />
            <SaveButton
              form="login"
              label="Kirish"
              endIcon={<Enter />}
              type="submit"
            />
          </form>
        </div>
      </div>
      <div className="login-right">
        <div>
          <img src={Main} alt="main" />
        </div>
        <img src={Rocket} alt="Rocket" className="rocket" />
        <img src={Student} alt="Student" className="student" />
        <img src={Book} alt="Book" className="book" />
        <img src={Time} alt="Time" className="time" />
      </div>
    </LoginStyled>
  );
};

export default Login;
