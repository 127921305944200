import Branch from "./assets/Branch";
import Course from "./assets/Course";
import Teacher from "./assets/Teacher";
import News from "./assets/News";
import Test from "./assets/Test";
import SettingsIcon from '@mui/icons-material/Settings';


const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;
export const sidebarWidth = "275px";

export const SIDEBAR_LIST = [
  {
    key: "1",
    icon: <Test/>,
    text: "Test natijalari",
    path: `/test_result?page=${DEFAULT_PAGE}&limit=${DEFAULT_LIMIT}`,
  },
  {
    key: "2",
    icon: <News />,
    text: "Yangilikar",
    path: `/project?page=${DEFAULT_PAGE}&limit=${DEFAULT_LIMIT}`,
  },
  {
    key: "3",
    icon: <Course />,
    text: "Kurslar",
    path: `/course?page=${DEFAULT_PAGE}&limit=${DEFAULT_LIMIT}`,
  },
  {
    key: "4",
    icon: <Teacher />,
    text: "O’qituvchilar",
    path: `/teacher?page=${DEFAULT_PAGE}&limit=${DEFAULT_LIMIT}`,
  },
  {
    key: "5",
    icon: <Branch />,
    text: "Filiallar",
    path: `/branch?page=${DEFAULT_PAGE}&limit=${DEFAULT_LIMIT}`,
  },
  {
    key: "6",
    icon: <SettingsIcon style={{color: "#0065FF"}} />,
    text: "Settings",
    path: `/update`,
  }
  
];
