import LoginProvider from "../context/UpdateProvider";
import Update from "./Update";

const index = () => {
  return (
    <LoginProvider>
      <Update />
    </LoginProvider>
  );
};

export default index;
