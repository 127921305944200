import { useContext, useEffect } from "react";
import { Input, SaveButton } from "components";
import { useForm } from "react-hook-form";
import { FORM_NAMES } from "../constants/Update";
import { LoginStyled } from "../style/Update";
import { UpdateContext } from "../context";
import { REQUEST_STATUS } from "hooks/useRequest/useRequest.constants";
import get from "lodash.get";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Book from "../assets/Book.png";
import Main from "../assets/Main.png";
import Pattern1 from "../assets/Pattern1.png";
import Pattern2 from "../assets/Pattern2.png";
import Rocket from "../assets/Rocket.png";
import Student from "../assets/Student.png";
import Time from "../assets/Time.png";
import Enter from "../assets/Enter";
import Logo from "../assets/Logo.png";

const Update = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    state: {
      updatePasswordStates: {
        updatePasswordData,
        updatePasswordStatus,
      },
    },
    actions: { updatePassword },
  } = useContext(UpdateContext);

  const update = (data: any) => {
    console.log(data);
    updatePassword(data);
  };

  useEffect(() => {
    if (updatePasswordStatus === REQUEST_STATUS.success) {
      toast.success(get(updatePasswordData, "message"));
      navigate("/test_result?page=1&limit=10");
      // window.location.reload();
    }
  }, [updatePasswordStatus]);

  return (
    <LoginStyled>
      <div className="login-left">
        <img src={Pattern1} alt="Pattern1" className="pattern1" />
        <img src={Pattern2} alt="Pattern2" className="pattern2" />
        <div className="login-form">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>
          <h2 className="login-page-title mb-4">Xush kelibsiz</h2>
          <form onSubmit={handleSubmit(update)} id="update">
            <Input
              control={control}
              errors={errors}
              rules={{
                required: { value: true, message: "To'ldirishingiz kerak" },
              }}
              FORM_NAMES={FORM_NAMES}
              name={FORM_NAMES.oldPassword}
              label="Eski parol"
              className="mb-3"
              placeholder="Eski parolni kiriting"
              type="password"
            />
            <Input
              control={control}
              errors={errors}
              rules={{
                required: { value: true, message: "To'ldirishingiz kerak" },
              }}
              FORM_NAMES={FORM_NAMES}
              name={FORM_NAMES.newPassword}
              label="Yangi parol"
              className="mb-4"
              placeholder="Yangi parolni kiriting"
              type="password"
            />
            <SaveButton
              form="update"
              label="Saqlash"
              endIcon={<Enter />}
              type="submit"
            />
          </form>
        </div>
      </div>
      <div className="login-right">
        <div>
          <img src={Main} alt="main" />
        </div>
        <img src={Rocket} alt="Rocket" className="rocket" />
        <img src={Student} alt="Student" className="student" />
        <img src={Book} alt="Book" className="book" />
        <img src={Time} alt="Time" className="time" />
      </div>
    </LoginStyled>
  );
};

export default Update;
