import styled from "styled-components";
import { TextAreaStyledType } from "./TextArea.types";

export const TextAreaStyled = styled.textarea<TextAreaStyledType>`
  /* border: 1px solid ${(props) => (props.isError ? "red" : "none")}; */
  border: none;
  outline: none;
  padding: 15px;
  height: 100px;
  width: 100%;
  font-size: 16px;
  background: #f5f5f5;
  border-radius: 16px;
  color: #232323;
  &::placeholder{
    color: #BDBDBD;
  }
`;
