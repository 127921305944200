import React from 'react'

const Course = () => {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.4 19.2857C7.015 19.2857 6.6853 19.146 6.4109 18.8664C6.13697 18.5864 6 18.25 6 17.8571V6.42857C6 6.03571 6.13697 5.69929 6.4109 5.41929C6.6853 5.13976 7.015 5 7.4 5H15.8C16.185 5 16.5147 5.13976 16.7891 5.41929C17.063 5.69929 17.2 6.03571 17.2 6.42857V11.4821C17.0833 11.4583 16.9578 11.4433 16.8234 11.4371C16.6895 11.4314 16.5817 11.4286 16.5 11.4286C15.1583 11.4286 14.0061 11.9136 13.0434 12.8836C12.0811 13.854 11.6 15.0357 11.6 16.4286C11.6 16.9405 11.6758 17.4405 11.8275 17.9286C11.9792 18.4167 12.2008 18.869 12.4925 19.2857H7.4ZM16.5 20C15.5317 20 14.7064 19.6519 14.0241 18.9557C13.3414 18.259 13 17.4167 13 16.4286C13 15.4405 13.3414 14.5981 14.0241 13.9014C14.7064 13.2052 15.5317 12.8571 16.5 12.8571C17.4683 12.8571 18.2939 13.2052 18.9766 13.9014C19.6589 14.5981 20 15.4405 20 16.4286C20 17.4167 19.6589 18.259 18.9766 18.9557C18.2939 19.6519 17.4683 20 16.5 20ZM16.1675 17.875L17.9525 16.7321C18.0575 16.6607 18.11 16.5595 18.11 16.4286C18.11 16.2976 18.0575 16.1964 17.9525 16.125L16.1675 14.9821C16.0392 14.9107 15.9167 14.9079 15.8 14.9736C15.6833 15.0388 15.625 15.1429 15.625 15.2857V17.5714C15.625 17.7143 15.6833 17.8186 15.8 17.8843C15.9167 17.9495 16.0392 17.9464 16.1675 17.875ZM9.325 11.1071L10.55 10.3571L11.775 11.1071C11.8917 11.1786 12.0083 11.1786 12.125 11.1071C12.2417 11.0357 12.3 10.9345 12.3 10.8036V6.42857H8.8V10.8036C8.8 10.9345 8.85833 11.0357 8.975 11.1071C9.09167 11.1786 9.20833 11.1786 9.325 11.1071Z" fill="#0065FF" />
            </svg>
        </div>
    )
}

export default Course