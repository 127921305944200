import styled from "styled-components";

export const LoginStyled = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  .login-left {
    height: 100vh;
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .pattern2 {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
    .pattern1 {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
    }
    .login-form {
      .login-page-title {
        font-weight: 600;
        font-size: 64px;
        line-height: 77px;
        color: #454545;
      }
      p {
        font-style: italic;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #0065ff;
      }

      form {
        input {
          padding: 14px !important;
        }
        .cancel {
          display: none !important;
        }

        button {
          width: 100%;
          border-radius: 8px;
          padding: 12px 0;
          text-transform: capitalize;
          background-color: #4340da !important;
        }
      }
    }
  }

  .login-right {
    height: 100vh;
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: linear-gradient(45deg, #0065ff 0%, #4e9dea 100%);

    .rocket {
      position: absolute;
      top: 48px;
      left: 48px;
    }
    .student {
      position: absolute;
      top: 20px;
      right: 36px;
    }
    .book {
      position: absolute;
      right: 36px;
      bottom: 36px;
    }
    .time {
      position: absolute;
      bottom: 36px;
      left: 36px;
    }
  }
`;
