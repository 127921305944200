import { useState, FC } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ModalMUI from "@mui/material/Modal";
import { SaveButton, CancelButton } from "components";

const Modal: FC<{
  label: string;
  isOpen: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}> = ({ children, label, isOpen, onSubmit, onCancel }) => {
  return (
    <div>
      <ModalMUI
        open={isOpen}
        // onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            textAlign={"center"}
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginBottom: "1rem" }}
          >
            {label}
          </Typography> 
          {children}
          <div style={{ display: "flex", gap: "20px" }}>
            <SaveButton label={"Ha"} onClick={onSubmit} />
            <CancelButton onClick={onCancel} />
          </div>
        </Box>
      </ModalMUI>
    </div>
  );
};
export default Modal;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};
