import styled from "styled-components";

export const LabelStyled = styled.label`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #454545;
  span{
    color: red;
  }
`;
