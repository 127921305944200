import styled from "styled-components";
import { sidebarWidth } from "components/shared/Sidebar/Sidebar.constants";
export const LayoutStyled = styled.div`
  height: 100vh;
  display: flex;

  .sidebar-content {
    width: ${sidebarWidth} !important;
    transition: all 0.3s linear;
  }
  .wrapper {
    overflow-y: auto;
    background-color: #fff;
    width: 100%;
    transition: all 0.3s linear;
    .wrapper-content {
      transition: all 0.3s linear;
      padding: 24px 32px;

      table {
        tbody {
          position: relative;
          top: 16px;
          tr {
            border-radius: 16px !important;
            &:hover {
              td,
              th {
                background-color: #f5f5f5;
              }
            }
            &:first-child {
              margin-top: 16px;
            }
            th {
              border: none !important;
              &:first-child {
                border-radius: 16px 0 0 16px;
              }
            }
            td {
              border-radius: 0 16px 16px 0;
              border: none !important;
            }
            &:nth-child(2n + 1) {
              th,
              td {
                background: #f5f5f5;
              }
            }
          }
        }
      }
    }
  }
`;
