import React, { FC, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { firstErrorField } from "../../../utils/firstErrorField/firstErrorField";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import Label from "../Label/Label";
import { InputContentStyled } from "./Input.style";
import { IInputProps } from "./Input.types";
import get from "lodash.get";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Input: FC<IInputProps> = ({
  control,
  name = "",
  rules = { required: false },
  id,
  label = "",
  className = "",
  errors,
  FORM_NAMES,
  type = "text",
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const focus = async () => {
    if (inputRef.current !== null) {
      if (errors) {
        if (!!get(errors, `${name}`, undefined)) {
          let key = firstErrorField(FORM_NAMES, errors);

          if (key === name) {
            inputRef?.current?.focus();
          }
        }
      }
    }
  };

  useEffect(() => {
    focus();
  }, [errors && get(errors, `${name}`, undefined)]);

  const { required } = rules;
  const { value } = required;

  const [visible, setVisible] = useState<boolean>(false);
  const changeType = () => {
    if (visible) setVisible(false);
    else setVisible(true);
  };

  return (
    <InputContentStyled
      className={className}
      isError={!!errors && !!get(errors, `${name}`, undefined)}
    >
      {label && <Label id={label} value={label} required={value} />}

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { ...field } }: any) => (
          <div className="input">
            <input
              {...props}
              {...field}
              type={
                type === "password" ? (visible ? "text" : "password") : type
              }
              value={field.value || ""}
              ref={inputRef}
            />
            {type === "password" ? (
              <div className="password" onClick={changeType}>
                {visible ? (
                  <VisibilityOffIcon style={{ color: "#454545" }} />
                ) : (
                  <VisibilityIcon style={{ color: "#454545" }} />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      />

      {errors && get(errors, `${name}`, undefined) && (
        <ErrorMessage value={get(errors, `${name}`)?.message} />
      )}
    </InputContentStyled>
  );
};

export default Input;
