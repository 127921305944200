import axios from "../../services/client/client";
import { useState } from "react";
// import toast from "react-hot-toast";

import { REQUEST_STATUS } from "./useRequest.constants";
import { TApiRequestMetod } from "./useRequest.types";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const useRequest = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any | undefined>();
  const [status, setStatus] = useState<string>(REQUEST_STATUS.initial);
  const [error, setError] = useState<any>();

  const get = async (url: string) => await sendRequest("get", url);

  const post = async (url: string, data: any, config: any = undefined) =>
    await sendRequest("post", url, data, config);

  const put = async (url: string, data: any, config: any = undefined) =>
    await sendRequest("put", url, data, config);

  const deleteRequest = async (url: string, data: any) =>
    await sendRequest("delete", url, { data });

  const sendRequest = async (
    method: TApiRequestMetod,
    url: string,
    data?: any,
    config?: any
  ) => {
    setStatus(REQUEST_STATUS.loading);
    try {
      console.log(
        `"request method: ", ${method} + ";\t url: ${url};\t body: ", ${data}, `
      );

      axios.defaults.timeout = 120000;
      const res = await axios[method](url, data, config);
      if (res.status === 401) {
        navigate("/login");
      }
      setData(res.data);
      setStatus(REQUEST_STATUS.success);
    } catch (err: any) {
      if (err?.response?.status === 401) {
        navigate("/login");
      }
      setError(err);
      setStatus(REQUEST_STATUS.failed);
      console.log(err?.response);
      toast.error(
        window.location.pathname === "/login" &&
          err?.response?.data?.message === "Validation error"
          ? "Login yoki parol xato"
          : err?.response?.data?.message
      );
    }
  };

  return [
    {
      get,
      post,
      put,
      deleteRequest,
    },
    data,
    status,
    error,
  ];
};
