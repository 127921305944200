import { useRequest } from "hooks/useRequest/useRequest";
import { IUpdatePasswordRequestData } from "./UpdateContext.types";

export const useUpdate = () => {
  const [
    updatePasswordClient,
    updatePasswordData,
    updatePasswordStatus,
    updatePasswordError,
  ] = useRequest();

  const updatePassword = (
    updatePasswordRequest: IUpdatePasswordRequestData
  ) => {
    updatePasswordClient.put("password", updatePasswordRequest);
  };

  return {
    state: {
      updatePasswordStates: {
        updatePasswordData,
        updatePasswordStatus,
        updatePasswordError,
      },
    },
    actions: { updatePassword },
  };
};
