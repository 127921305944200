import styled from "styled-components";

export const TableAction = styled.div`
  margin-bottom: 24px;
  .first-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .search-row {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius: 16px;
    padding: 0 16px;
    gap: 5px;
    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      svg path {
        fill: #bdbdbd;
      }
    }
    input {
      width: 500px;
      outline: none;
      border: none;
      padding: 18px 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      background: #f5f5f5;
      border-radius: 16px;
      &::placeholder {
        color: #bdbdbd;
      }
    }
  }
`;
