
import React, { FC } from "react";
import { RightSidebarStyled, AddStyled } from "./RightSidebar.style";
import { IRightSidebar } from "./RightSidebar.types";

const RightSidebar: FC<IRightSidebar> = ({
  children,
  open,
  setOpen,
  title,
  onClose = () => {},
}) => {
  return (
    <AddStyled open={open}>
      <div className="shadow" onClick={()=>onClose()}></div>
      <div className="right">
        <RightSidebarStyled open={open}>
          <div>
            <h2>{title}</h2>
            {children}
          </div>
          
        </RightSidebarStyled>
      </div>
    </AddStyled>
  );
};

export default RightSidebar;
