import { ICustomProviderProps } from "interfaces/form";
import UpdateContext from "./UpdateContext";
import { useUpdate } from "./useUpdate";

const UpdateProvider = ({ children }: ICustomProviderProps) => {
  const value = useUpdate();
  return (
    <UpdateContext.Provider value={value}>{children}</UpdateContext.Provider>
  );
};

export default UpdateProvider;
