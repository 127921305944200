import Login from "./container";

const index = () => {
  return (
    <>
     <Login />
    </>
  );
};

export default index;
