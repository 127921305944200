import { Suspense, useEffect } from "react";
import { ThemeProvider } from "@emotion/react";
import { Loader } from "components";
import { Toaster } from "react-hot-toast";
import { LightTheme } from "styles/theme/LightTheme";
import Layout from "./layout/container";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import isToken from "utils/isToken/isToken";
import Login from "pages/login";  
import Update from "pages/update";
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/login") {
      if (isToken()) {
        navigate(-1);
      }
    }
  }, []);
  return (
    <ThemeProvider theme={LightTheme}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="*" element={<Layout />} />
          <Route path="/login" element={<Login />} />
          <Route path="/update" element={<Update />} />
        </Routes>
        <Toaster containerStyle={{ zIndex: "100000" }} />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
