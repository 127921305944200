import get from "lodash.get";
import React, {
  createRef,
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller } from "react-hook-form";
import { useRequest } from "../../../hooks/useRequest/useRequest";
import { REQUEST_STATUS } from "../../../hooks/useRequest/useRequest.constants";
import { firstErrorField } from "../../../utils/firstErrorField/firstErrorField";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import UploadFileIcon from "./assets/UploadFileIcon";
import {
  FocusInputStyled,
  UploadedFile,
  UploadFileIconLoader,
  UploadFileStyled,
} from "./UploadFile.style";
import { UploadFilePropsType } from "./UploadFile.types";
import { setErrorFunction } from "./utils/setErrorFunction";
import { sliceUrl } from "./utils/sliceUrl";
import UploadIcon from "./assets/UploadImageIcon";
import UploadImageIcon from "./assets/UploadIcon";
import ChangeImageIcon from "./assets/ChangeImageIcon";
import { CircularProgress } from "@mui/material";

const UploadFile: FC<UploadFilePropsType> = (props) => {
  const {
    upload = () => {},
    name = "file",
    dataUrl,
    className = "",
    control,
    errors,
    rules = { required: false },
    setError,
    id,
    FORM_NAMES,
    placeholder,
    file,
    setFile,
  } = props;

  const [client, data, status] = useRequest();
  const uploadRef = useRef<HTMLInputElement>(null);

  const focus = async () => {
    if (uploadRef.current !== null) {
      if (errors) {
        if (!!get(errors, `${name}`, undefined)) {
          let key = firstErrorField(FORM_NAMES, errors);

          if (key === name) {
            uploadRef?.current?.focus();
          }
        }
      }
    }
  };

  useEffect(() => {
    focus();
  }, [errors && get(errors, `${name}`), uploadRef]);

  const fileUpload = async (e: any) => {
    if (e.target.files[0]) {
      try {
        setFile({ ...file, name: e.target.files[0]?.name });
        console.log(file);
        const formState = new FormData();
        formState.append("file", e.target.files[0]);
        formState.append("type", "img");
        await client.post("upload", formState);
      } catch (e: any) {
        setFile(undefined);
        setErrorFunction(name, setError, errors);
      }
    } else {
      setFile(undefined);
    }
  };

  useEffect(() => {
    if (status === REQUEST_STATUS.success) {
      upload(data?.data);
      setFile({ ...file, url: data?.data });
      console.log(file);
      // clearErrors(name);
    } else if (status === REQUEST_STATUS.failed) {
      setFile(undefined);
      setErrorFunction(name, setError, errors);
    }
  }, [status]);

  const renderUploadContext = () => {
    if (status === REQUEST_STATUS.success && !file)
      return (
        <div className="uploadIcon">
          <UploadIcon />
          <p>{placeholder}</p>
        </div>
      );
    else if (status === REQUEST_STATUS.loading) {
      return (
        <div className=" loaderContent  justify-content-center align-items-center">
          <CircularProgress />
        </div>
      );
    } else if (status === REQUEST_STATUS.success || file) {
      return (
        <UploadedFile>
          <div className="action">
            <div className="uploaded">
              <UploadImageIcon />
              {sliceUrl(file?.name)}
            </div>
            <div className="change">
              <ChangeImageIcon />
            </div>
          </div>
        </UploadedFile>
      );
    } else
      return (
        <div className="uploadIcon">
          <UploadIcon />
          <p>{placeholder}</p>
        </div>
      );
  };

  return (
    <div className={className}>
      <FocusInputStyled type="text" ref={uploadRef} />
      <UploadFileStyled
        isError={!!errors && !!get(errors, `${name}`, undefined)}
        img={file}
      >
        <label htmlFor={id}>
          <Controller
            name={name}
            rules={rules}
            control={control}
            render={({ field: { onChange, value, ...field } }) => {
              return (
                <input
                  className="uploadFile"
                  type="file"
                  id={id}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    fileUpload(e);
                  }}
                  accept={".doc, .docx, .xlsx, .ppt, .pptx, .xls"}
                  disabled={status === REQUEST_STATUS.loading}
                  {...field}
                />
              );
            }}
          />
        </label>
        {renderUploadContext()}
      </UploadFileStyled>
      {errors && !!get(errors, `${name}`, undefined) && (
        <ErrorMessage value={get(errors, `${name}`)?.message} />
      )}
    </div>
  );
};

export default UploadFile;
