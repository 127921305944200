import { CircularProgress } from "@mui/material";
import { LoaderStyled } from "./Loader.styles";
import "./Loader.styles.ts";

const Loader = () => { 
  return (
    <LoaderStyled>
      {/* <span className="loader"></span> */}
      <CircularProgress sx={{fontSize: 50}} />
    </LoaderStyled>
  );
};

export default Loader;
