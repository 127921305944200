import { Button } from "@mui/material";
import get from "lodash.get";
import styled from "styled-components";

export const SaveButtonStyled = styled(Button)`
  padding: 16px 60px !important;
  overflow: hidden;
  text-transform: capitalize !important;
  position: relative;
  color: ${({ theme }) => get(theme, "palette.common.white")} !important;
  background: linear-gradient(45deg, #0065ff 0%, #4e9dea 100%);
  border-radius: 16px !important;
  box-shadow: none !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  .buttonLoader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    .backgroundOpacity {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: #fff;
      opacity: 0.4;
      z-index: 1;
    }
    .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
      margin: 0 auto;
      color: #fff;
      width: 35px !important;
      height: 35px !important;
    }
  }
`;
