import styled from "styled-components";
import MaskInput from "react-input-mask";
export const MaskInputStyled = styled(MaskInput)`
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  padding:12px;
  border: 1px solid #EDF1FF;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  outline: none;
  transition: all 300ms ease-out;
  background-color: #f6f6f6;
  color: #333;

  &::placeholder {
    color: #c3c2c1;
  }
  /* &:focus {
    box-shadow: none;
    color: #8b90a5;
  } */
  &.error {
    border-color: red;
  }
`;
